import React, { useState, useEffect } from "react";
import Constant from "../../../utility/constants";
import { getData, postData } from "../../../services/customApis";
import APIEndPoints from "../../../utility/apiendpoints";
import { Image } from "@fluentui/react-northstar";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import PageNotFound from "../../../commonui/pagenotfound/pageNotFound";
import UnauthorizedPage from "../../../commonui/unauthorizedpage/unauthorized";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { LuArrowUpToLine } from "react-icons/lu";
import { app } from "@microsoft/teams-js";
import CustomReport from "../landingPage/customReport";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import UploadReport from "../../../commonui/uploadreport/uploadReport";
import { useLocation } from "react-router-dom";
import { PiArrowArcRight } from "react-icons/pi";
import {
  AiFillExclamationCircle
} from "react-icons/ai";
import { Alert } from "@fluentui/react-northstar";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import { UseLibrarySearchFormContext } from "../../../context/librarysearchformcontext/librarySearchFormContext";
import './visualizationReport.css';

const VisualizationReport = () => {
  const location = useLocation();
  const {
    internalAppDetails,
    reportTemplateId,
    templateDetails,
    privilagesForPowerKApp,
    reportDetails,
    librarySearchForm,
    executeData,
    searchFormPayload,
    fileSpecialCharacters
  } = location.state || {};
  let { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const [loading, setLoading] = useState(true);
  const [showUploadReportPopup, setShowUploadReportPopup] = useState(false);
  const [htmlString, setHtmlString] = useState("");
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [errorApiCall, setErrorApiCall] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const [customReportPayload, setCustomReportPayload] = useState({});
  const { librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
    UseLibrarySearchFormContext();

  useEffect(() => {
    try {
      const resizeWindowScriptIdentifier = "resize_window_script";
      const script2 = document.createElement('script');
      const topbar = document.getElementById("topbar");
      const topbarHeight = topbar?.getBoundingClientRect()?.height; // Height of the topbar
      const viewportHeight = window?.innerHeight; // Total visible height of the viewport
      let remainingHeight = viewportHeight - topbarHeight - 120; // Remaining visible height

      console.log("Remaining visible height:", remainingHeight);

      remainingHeight = remainingHeight < 600 ? 600 : remainingHeight; // Set a minimum height of 600px

      const scriptsToRemove = document.querySelectorAll(
        `script[data-id="${resizeWindowScriptIdentifier}"]`
      );
      if (scriptsToRemove.length != 1) {
        scriptsToRemove.forEach((script) => {
          document.body.removeChild(script);
        });
        script2.textContent = `window.resizePlotlyCharts = function() {
                          setTimeout(() => {
                              try {
                                  let parentDiv = document.getElementById("customReportContainerId");
                                  if (parentDiv) {
                                      document.querySelectorAll('.js-plotly-plot').forEach(function (chart) {
                                          Plotly.relayout(chart, { height: `+ remainingHeight + `, width: parentDiv.offsetWidth - 30 }); 
                                      });
                                  } else {
                                      console.error("Parent div not found");
                                  }
                              } catch (error) {
                                  console.error("Error resizing Plotly charts:", error);
                              }
                      }, 1000);
                  };
  
                  window.addEventListener('resize', window.resizePlotlyCharts);
                  
                  `;
        script2.setAttribute("data-id", resizeWindowScriptIdentifier);
        document.body.appendChild(script2);

      }
    } catch (error) {
      console.error("Error in resizing window:", error);
    }
  }, []);

  useEffect(() => {
    if (librarySearchFormJsonData.specialCharacters.length < 2) {
      getData(APIEndPoints.GET_ALL_SPECIAL_CHARACTERS).then((response) => {
        if (response?.data) {
          handleLibrarySearchFormJsonData({
            specialCharacters: response?.data?.textSpecialCharacters,
          });
        }
      });
    }
    if (privilagesForPowerKApp?.exportAll?.exportAllEnabled) {
      setIsExportDisabled(false);
    }
    else {
      setIsExportDisabled(true);
    }
    const viewSearchResults = async () => {
      if (
        internalAppDetails?.contentTypeId ==
        Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
      ) {
        // let templatID = librarySearchForm?.templateConfig?.vizualisationReportId;
        // const decodeUniqeId = atob(executeData?.searchQuery);
        // let obj = {
        //   templateid: templatID,
        //   uniqueid: decodeUniqeId,
        // };
        // let reportDetail = [];
        // reportDetail.push(obj);
        // let postParams = {
        //   contentType: internalAppDetails?.contentTypeId,
        //   reportDetail: reportDetail[0],
        // };
        // getPowerBIReportDetails(postParams, userInfo);
        setHtmlString(() => { return ""; });
        let formData = new FormData();
        let customData = JSON.stringify({
          companyId: userInfo?.teams?.companyId,
          appType: userInfo?.loggedInWeb ? 1 : 2,
          teamsAppId: await getTeamsAppId(userInfo),
        });
        let payload = {
          filters: {
            fields: {}
          },
          customData: customData,
          environment: window.location.hostname.includes("dev") ? "Dev" : window.location.hostname.includes("qa") ? "QA" : ""
        };
        formData.append("appId", internalAppDetails.appID);
        formData.append("templateId", librarySearchForm?.templateConfig?.templateId);
        formData.append("requestJson", JSON.stringify(payload));
        formData.append("searchQuery", executeData?.searchQuery);
        formData.append("visualizationReportId", librarySearchForm?.templateConfig?.vizualisationReportId);

        try {
          setCustomReportPayload({
            formData: {
              appId: internalAppDetails.appID,
              templateId: librarySearchForm?.templateConfig?.templateId,
              requestJson: payload,
              searchQuery: executeData?.searchQuery,
              visualizationReportId: librarySearchForm?.templateConfig?.vizualisationReportId,
              customData: customData,
            },
            contentTypeId: internalAppDetails?.contentTypeId,
            companyId: userInfo?.teams?.companyId,
          });
          const response = await postData(
            formData,
            APIEndPoints.GET_CUSTOM_REPORTS(
              internalAppDetails?.contentTypeId,
              userInfo?.teams?.companyId
            ),
            true
          );
          if (!response) {
            showNotification(
              Constant.INTERNALAPP_CONSTANTS.CUSTOM_REPORT_EXCEPTION(searchFormPayload?.customData?.totalSearchResults),
              "Fail"
            );
            setIsExportDisabled(true);
            setLoading(false);
          }
          else {
            console.log(response.data ? atob(response.data?.htmlString) : "");
            setLoading(false);
            if (response.data?.htmlString != "" && response.data != "") {
              setHtmlString(() => { return atob(response.data?.htmlString); });
              if (response.data?.actualCount > response.data?.totalCount) {
                showNotification(
                  Constant.INTERNALAPP_CONSTANTS.CUSTOM_REPORT_MISSING_VALUES_EXCEPTION(response.data?.actualCount, response.data?.totalCount),
                  "Info"
                );
              }
            }
            else {
              showNotification(
                Constant.INTERNALAPP_CONSTANTS.CUSTOM_REPORT_EXCEPTION(searchFormPayload?.customData?.totalSearchResults),
                "Fail"
              );
              setIsExportDisabled(true);
            }
          }
        }
        catch (err) {
          if (err?.response?.data?.errors[0] && err?.response?.data?.errors[0]?.toLowerCase() === "access denied.") {
            setAccessDenied(true);
          } else {
            showNotification(
              Constant.SOMETHING_WENTWRONG,
              "Fail"
            );
          }
          setIsExportDisabled(true);
          setLoading(false);
          return;
        }
      }
    }
    if (executeData?.actualCount == 0) {
      setLoading(false);
      // showNotification(Constant.INTERNALAPP_CONSTANTS.MISSING_SEARCH_DATA, "Info");
    } else {
      viewSearchResults();
    }
  }, []);

  const updateLoader = () => {
    if (loading)
      setLoading((prevState) => !prevState);
  };

  const getTeamsAppId = async (userInfo) => {
    if (userInfo?.loggedInWeb) {
      return null;
    }
    try {
      const { user } = await app.getContext();
      const email = user?.userPrincipalName ?? userInfo?.email;
      return Constant.GetUserAppId(email);
    } catch (error) {
      console.error('Error fetching Teams App ID:', error);
      return null;
    }
  };
  const showNotification = (msg, type) => {
    handleNotificationMsgsData({
      showMsgBar: true,
      started: false,
      completed: true,
      msg,
      type,
      isFailed: false,
    });
  };
  const exportFootnoteReports = async () => {
    const tempId = templateDetails?.templateId;
    // for footnotes, metaDataIds is empty or blank array
    const metaDataIds = [];
    const { appID, contentTypeId } = internalAppDetails || {};
    const formData = new FormData();
    formData.append("appId", appID);
    formData.append("templateId", tempId);
    formData.append("isExportAll", true);
    formData.append("version", 2);
    const jsonStr = JSON.stringify({ "metaDataIds": metaDataIds });
    const file = new Blob([jsonStr], { type: 'application/json' });
    formData.append("attachment", file, 'request.json');
    formData.append("searchId", internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES ? executeData?.searchQuery : "");
    if (
      internalAppDetails?.contentTypeId ==
      Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
    ) {
      formData.append("customData", JSON.stringify({
        companyId: userInfo?.teams?.companyId,
        appType: userInfo?.loggedInWeb ? 1 : 2,
        teamsAppId: await getTeamsAppId(userInfo),
      }));
    }
    try {
      const response = await postData(
        formData,
        APIEndPoints.DOWNLOAD_EXCEL_REPORT_INTERNAL_APPS(internalAppDetails?.contentTypeId, userInfo?.teams?.companyId),
        true
      );

      if (response.status !== 200) {
        showNotification(
          Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG,
          "Fail"
        );
        return;
      }
      else if (response.status == 200) {
        showNotification(Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_PROGRESS_MSG, "Info");
        return;
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
      if (errorMessage === "access denied.") {
        setAccessDenied(true);
      } else {
        const message =
          errorMessage || Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG;
        showNotification(message, "Fail");
      }
    }
  };
  if (errorApiCall) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;
  return (
    <>
      {loading &&
        <ReusableLoader isOverlay={true} isAppBody={true} />
      }{
        <div>
          <div className="container_internalApps common_container_subtitle_internalApp">
            <div className="common_container_rightMenu_internalApp">
              <div className="divTxtSubmenu_internalApp">
                <header className="headerStyle">
                  <p className="pStyle">
                    {
                      <>
                        <span className="commonTitleEllipses_internalApp showEllipses_internalApp">
                          {Constant.INTERNALAPP_CONSTANTS.VISUALIZATION_REPORT}
                        </span>
                      </>
                    }
                  </p>
                </header>
              </div>
              <div className="dataBaseLink_internalApp topbar_internalApp">
                {
                  <div className="backToLibrarySearchLink_PKLibrary">
                    <Link
                      to={"/apps/LibrarySearchPage"}
                      state={{
                        appDetails: internalAppDetails,
                        privilagesPowerKApp: privilagesForPowerKApp,
                        templateDetails: templateDetails,
                      }}
                      className="linkStyleDetails_powerKLibrary"
                    >
                      {" "}
                      <IoArrowBack />{" "}
                      {Constant.INTERNALAPP_CONSTANTS.BACK_TO_LIBRARY_SEARCH}
                    </Link>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className={`rightMenu_second_internalApp ${ executeData?.actualCount != 0 ? "missingLeftContainer" : ""}`}>
            <div className="flex-container">
              {executeData?.actualCount == 0 &&
                <div className="left-side">

                  <Alert
                    content={
                      <div className="missingDataAlertContent">
                        <span><AiFillExclamationCircle /></span>
                        <span>{Constant.INTERNALAPP_CONSTANTS.MISSING_SEARCH_DATA}</span>
                      </div>
                    }
                    visible={true}
                    warning={true}
                    className="missingDataAlert"
                  />

                </div>
              }
              <div className="right-side">
                {internalAppDetails?.contentTypeId === Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES &&
                  reportDetails?.isVisible == true && privilagesForPowerKApp?.uploadReport?.uploadReportDisplay &&
                  <div>
                    <a
                      onClick={() => { setShowUploadReportPopup(true); }}
                      disabled={!reportDetails?.isEnabled && !privilagesForPowerKApp?.uploadReport?.uploadReportEnabled}
                      className={"uploadReportClass"}
                    >
                      <LuArrowUpToLine />
                      {reportDetails?.displayName}
                    </a>
                  </div>
                }
                {showUploadReportPopup &&
                  <UploadReport
                    reportDetails={reportDetails}
                    reportTemplateId={reportTemplateId}
                    fileSpecialCharacters={fileSpecialCharacters}
                    internalAppDetails={internalAppDetails}
                    showUploadReportPopup={showUploadReportPopup}
                    setShowUploadReportPopup={setShowUploadReportPopup}
                    setAccessDenied={setAccessDenied}
                    showNotification={showNotification}
                  />
                }
                {privilagesForPowerKApp?.exportAll?.exportAllDisplay && (
                  <div>
                    <a
                      onClick={exportFootnoteReports}
                      disabled={isExportDisabled}
                      className={"downloadReportClass"}
                    >
                      <PiArrowArcRight />
                      {Constant.INTERNALAPP_CONSTANTS.EXPORT_EXCEL_ALL}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          {
            executeData?.actualCount == 0 ?
              <div className="missingData">
                <p className="missingDataP">Missing data</p>
                <Image
                  alt=""
                  src={require("../../../assets/image.png")}
                  fit="cover"
                />
                <p className="missingDataP2">Please refine your search criteria to view the visualization.</p>
              </div>
              :
              <CustomReport
                updateLoader={updateLoader}
                htmlString={htmlString}
                setHtmlString={setHtmlString}
                customReportPayload={
                  customReportPayload
                }
              />
          }
        </div>
      }
    </>
  );
}

export default VisualizationReport;