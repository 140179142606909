import React, { useState } from "react";
import UploadData from "../uploaddata/UploadData";
import Attachment from "../../commonui/attachment/Attachment";
import { GrClose } from "react-icons/gr";
import { MdCloudUpload } from "react-icons/md";
import { AiFillExclamationCircle, AiFillFileText } from "react-icons/ai";
import FooterTechButtons from "../commonformcomponents/footertechbuttons/FooterTechButtons";
import Constant from "../../utility/constants";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { getData, postData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import {
    formatBytes
} from "../../utility/utility";
import { Text } from "@fluentui/react-northstar";
import PageNotFound from "../../commonui/pagenotfound/pageNotFound";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import "./uploadReport.css";

const UploadReport = ({ reportDetails, reportTemplateId, fileSpecialCharacters, internalAppDetails, showUploadReportPopup, setShowUploadReportPopup }) => {
    let { userInfo } = UseUserInfoContext();
    const { handleNotificationMsgsData } = UseToasterNotificationsContext();
    const [loader, setLoader] = useState(false);
    const [errorAccessDenied, setAccessDenied] = useState(false);
    const [errorApiCall, setErrorApiCall] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [isDropzoneEnabled, setIsDropzoneEnabled] = useState(true);
    const [attachmentValidationMsg, setattachmentValidationMsg] = useState({
        message: "",
        IsValid: true,
        isError: false,
    });
    const showNotification = (msg, type) => {
        handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg,
            type,
            isFailed: false,
        });
    };
    const dropzoneContent = () => {
        return (
            <div className="dragDropContainer">
                <MdCloudUpload className="uploadButton" />
                <p>
                    {Constant.LANDING_PAGE_CONSTANTS.DRAG_DROP_HEADER}{" "}
                    <span
                        className={isDropzoneEnabled === true ? "browse" : "browseDisabled"}
                    >
                        {Constant.LANDING_PAGE_CONSTANTS.BROWSE_BUTTON_TEXT}
                    </span>
                </p>
                <p>
                    {Constant.LANDING_PAGE_CONSTANTS.ACCEPTED_XMLFILE_TEXT}{" "}
                </p>
            </div>
        );
    };
    const displayAttachments = () => {
        if (attachmentValidationMsg?.isError) {
            return (
                <div className="errorMessage">
                    <span><AiFillExclamationCircle className="iconStyle" /></span>
                    <Text
                        error
                        content={attachmentValidationMsg?.message}
                        align="start"
                        weight="regular"
                    />
                </div>
            );
        }
    };
    const attachmentTitle = () => {
        return (
            <div
                title={`${attachment[0]?.name.length > 50 ? attachment[0]?.name : ""
                    }`}
                className="dataDropAttachments"
            >
                <span className={`ProgressbarHeader`}>
                    <AiFillFileText /> {attachment[0]?.name}
                </span>
            </div>
        );
    };
    const handleDropFileDialog = (files, fileTypes) => {
        let validation = validateFile(files[0], fileTypes);
        if (validation.IsValid) {
            setAttachment(files);
            setIsDropzoneEnabled(false);
            setattachmentValidationMsg(validation);
        }
        else {
            setAttachment(attachment.length > 0 ? attachment : []);
            setIsDropzoneEnabled(true);
            setattachmentValidationMsg(validation);
        }
    };
    const validateFile = (files, allowedFiles) => {
        let validation = { IsValid: true, message: "", isError: false };

        let sanitizedFileName = files.name.replace(/\s+/g, "");
        let regex = new RegExp(fileSpecialCharacters);
        let fileNameRegex = new RegExp("(" + allowedFiles.join("|") + ")$");
        let fileSize = formatBytes(files.size).split(" ");
        if (!fileNameRegex.test(sanitizedFileName.toLowerCase())) {
            validation.IsValid = false;
            validation.isError = true;
            validation.message = Constant.LANDING_PAGE_CONSTANTS.INCORRECT_FILE_TYPE;
        } else if (!regex.test(sanitizedFileName?.replace(/[a-zA-Z0-9]/g, '')?.toLowerCase())) {
            validation.IsValid = false;
            validation.isError = true;
            validation.message = Constant.LANDING_PAGE_CONSTANTS.SPECIAL_CHARACTERS_TEXT(fileSpecialCharacters);
        } else if (sanitizedFileName.length > 250) {
            validation.IsValid = false;
            validation.isError = true;
            validation.message = Constant.FILENAME_LENGTH_MESSAGE;
        } else if (files.size === 0) {
            validation.IsValid = false;
            validation.isError = true;
            validation.message = Constant.EMPTY_FILE_MESSAGE;
        } else if (
            parseInt(fileSize[0].trim()) > 295 &&
            ["MB", "GB", "TB", "PB", "EB", "ZB", "YB"].includes(fileSize[1].trim())
        ) {
            validation.IsValid = false;
            validation.isError = true;
            validation.message = Constant.LANDING_PAGE_CONSTANTS.FILESIZE_LIMIT_MESSAGE;
        }
        return validation;
    };
    const onRemoveItem = () => {
        setAttachment([]);
        setIsDropzoneEnabled(true);
        setattachmentValidationMsg({
            message: "",
            IsValid: true,
            isError: false
        });
    };

    const uploadFootnoteExcelReport = async () => {
        setLoader(true);
        setShowUploadReportPopup(false);
        const tempId = reportTemplateId;
        const companyId = userInfo?.teams?.companyId;
        const fileName = attachment[0].name;
        const { appID, contentTypeId } = internalAppDetails || {};
        const formData = new FormData();
        formData.append("appId", appID);
        formData.append("templateId", tempId);
        formData.append("ExcelReportDocument", attachment[0]);

        try {
            const response = await postData(
                formData,
                APIEndPoints.UPLOAD_REPORTS(contentTypeId, companyId),
                true
            );
            onRemoveItem();
            if (response.status === 200) {
                showNotification(Constant.INTERNALAPP_CONSTANTS.REPORT_UPLOAD_SUCCESS, "Info");
            } else {
                showNotification(Constant.INTERNALAPP_CONSTANTS.REPORT_FAILED_MSG(fileName), "Fail");
            }
        } catch (error) {
            console.error(error);
            onRemoveItem();
            const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
            if (errorMessage === "access denied.") {
                setAccessDenied(true);
            } else {
                const message = errorMessage || Constant.INTERNALAPP_CONSTANTS.REPORT_FAILED_MSG(fileName);
                showNotification(message, "Fail");
            }
        } finally {
            setLoader(false);
        }
    };
    if (errorApiCall) return <PageNotFound />;
    if (errorAccessDenied) return <UnauthorizedPage />;
    return (
        <DialogBox
            openDialog={showUploadReportPopup}
            headerName={reportDetails?.header}
            headerAction={{
                icon: <GrClose />,
                title: 'Close',
                onClick: () => onRemoveItem(),
            }}
            content={
                <>
                    {
                        loader === true &&
                        <div className={loader && "loaderLayoverUploadReport"}>
                            <ReusableLoader
                                isOverlay={true}
                                labelPosition={"start"}
                            />
                        </div>
                    }
                    <div className={loader ? "uploadReportLayover" : ""}>
                        <p>{reportDetails?.content}</p>
                        <div className="dropzoneContentBody">
                            <UploadData
                                multiple={reportDetails?.multiUpload}
                                fileTypes={reportDetails?.typesSupported}
                                isDropzoneEnabled={reportDetails?.isEnabled}
                                dropzoneContent={dropzoneContent}
                                handleDropFile={handleDropFileDialog}
                                rootProps={
                                    isDropzoneEnabled === true
                                        ? { className: "dropzoneBody" }
                                        : { className: "dropzoneBodyDisabled" }
                                }
                            />
                        </div>
                        <Attachment
                            files={attachment}
                            title={attachmentTitle}
                            description={
                                attachment?.length > 0
                                    ? formatBytes(attachment[0]?.size)
                                    : null
                            }
                            onRemoveClick={onRemoveItem}
                            actionTitle={"Remove"}
                            actionIcon={<GrClose />}
                            isErrorClass={false}
                            actionable={true}
                            isModel={true}
                            removeTransition={true}
                        />
                        {displayAttachments()}
                        <div className="footerUploadReport">
                            <FooterTechButtons
                                disabled={attachment?.length > 0 ? false : true}
                                cancelText={reportDetails?.cancelText}
                                sendText={reportDetails?.uploadText}
                                onClickAddData={() => { uploadFootnoteExcelReport(); }}
                                onClickCancel={() => {
                                    setShowUploadReportPopup(false);
                                    onRemoveItem();
                                }}
                            />
                        </div>
                    </div>
                </>
            }
        />
    );
};

export default UploadReport;
