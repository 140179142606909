import React, { useEffect, useState } from "react";
import TopBar from "../../components/topbar/topBar";
import UnauthorizedPage from "./unauthorized";
import PrivacyNoticePopup from "../../components/privacynotice/privacyNoticePopup";
import { UseUserInfoContext } from "../../context/usercontext/userContext";

export default function UnauthorizedWithTopBar(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const { userInfo } = UseUserInfoContext();

  useEffect(() => {
    if (userInfo.teams.companyId) {
      setOpenDialog(!userInfo.teams.privacyAgreementAccepted);
    }
  }, [userInfo.teams.companyId, userInfo.showPopup]);
  return (
    <>
      <TopBar context={props.context} unauthorize />
      {!openDialog ? (
        <UnauthorizedPage />
      ) : (
        <PrivacyNoticePopup setOpenDialog={setOpenDialog} />
      )}
    </>
  );
}
