import React, { useRef, useEffect, useState } from 'react';
import APIEndPoints from '../../../utility/apiendpoints';
import { postData } from '../../../services/customApis';

function CustomReport({ updateLoader, htmlString, setHtmlString, customReportPayload }) {
    const containerRef = useRef(null);
    const [plotlyLoaded, setPlotlyLoaded] = useState(false);
    const [htmlStrings, setHtmlStrings] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [addedOrgDropdownEvent, setAddedOrgDropdownEvent] = useState(false);
    const scriptIdentifier = "custom_report_dynamic_script";

    useEffect(() => {
        if (htmlString) {
            containerRef.current.innerHTML = htmlString;
            const scriptTags = containerRef.current.querySelectorAll("script");
            scriptTags.forEach((script) => {
                const newScript = document.createElement("script");
                newScript.textContent = script.textContent;
                newScript.setAttribute("data-id", scriptIdentifier);
                document.body.appendChild(newScript);
                document.body.removeChild(newScript);
            });
            updateLoader();
        }
    }, [htmlString, plotlyLoaded]);

    useEffect(() => {
        if (!document.querySelector('script[src="https://cdn.plot.ly/plotly-latest.min.js"]')) {
            const script = document.createElement('script');
            script.src = "https://cdn.plot.ly/plotly-latest.min.js";
            script.async = true;
            script.onload = () => {
                setPlotlyLoaded(true);

            };
            script.onerror = () => {
                setPlotlyLoaded(false);
            };
            document.body.appendChild(script);
        } else {
            setPlotlyLoaded(true);
        }
        return () => {
        };
    }, []);


    async function myCustomFunction(chartId, orgName, multi_org = null) {


        let chartIdDiv = "";
        if (multi_org) {
            chartIdDiv = multi_org;
        }
        else if (chartId === "ETRBreakDown") {
            chartIdDiv = chartId;
        }
        else {
            chartIdDiv = chartId + '_single_org';
        }
        let chartDiv = document.getElementById(chartIdDiv);

        if (chartDiv) {
            chartDiv.innerHTML = ' <div style=" width: 100%; height: 300px;margin-bottom:50px;"><div class="custom_report_loader"></div> </div>';
        }
        let orgnaizationName;
        if (Array.isArray(orgName)) {
            orgnaizationName = orgName.map((org) => btoa(org));
        } else {
            orgnaizationName = [btoa(orgName)];
        }
        let formData = new FormData();
        let formDataObj = customReportPayload.formData;
        let payload = {
            filters: {
                chartFilters: {
                    chartType: chartId,
                    organizationName: orgName
                },
                fields: {
                    P_0037: orgnaizationName
                }
            },
            customData: formDataObj.customData,
            environment: window.location.hostname.includes("dev") ? "Dev" : window.location.hostname.includes("qa") ? "QA" : ""
        };


        formData.append("appId", formDataObj.appId);
        formData.append("templateId", formDataObj.templateId);
        formData.append("requestJson", JSON.stringify(payload));
        formData.append("searchQuery", formDataObj.searchQuery);
        formData.append("visualizationReportId", formDataObj.visualizationReportId);
        const response = await postData(
            formData,
            APIEndPoints.GET_CUSTOM_REPORTS(
                customReportPayload?.contentTypeId,
                customReportPayload.companyId
            ),
            true
        ).then((response) => {
            let res = atob(response.data?.htmlString);


            // chartIdDiv = chartId + chartIdDiv;

            if (chartDiv) {
                chartDiv.innerHTML = res;
                const scriptTags = chartDiv.querySelectorAll("script");
                scriptTags.forEach((script) => {
                    const newScript = document.createElement("script");
                    newScript.textContent = script.textContent;
                    newScript.setAttribute("data-id", scriptIdentifier);
                    document.body.appendChild(newScript);
                    document.body.removeChild(newScript);
                });
            }
            setTimeout(() => {
                setHtmlStrings(res);
                if (chartId === "ETRBreakDown") {
                    addClickEventToPlotly();
                }
            }, 200);
            setTimeout(() => {
                window.resizePlotlyCharts();
            }, 1000);
        });

    }
    const onDrillThrough = (chartId, data) => {
        var organizationName = data;
        document.getElementById(chartId + '_btn')?.click();
        myCustomFunction(chartId, organizationName);
        addDropdownEventListner();

    };

    function handlePlotlyClick(data, plotDiv) {
        data.event.preventDefault();
        var { link, organizationName } = JSON.parse(data.points[0].customdata);
        // myCustomFunction(organizationName);
        const contextMenu = document.getElementById("context-menu");
        const orgNameId = document.getElementById("orgName");
        const sourceDocumentLink = document.getElementById("source_document_link");
        if (orgNameId) {
            orgNameId.innerText = organizationName;
        }

        if (sourceDocumentLink) {
            sourceDocumentLink.href = link;
        }

        // event.preventDefault(); // Prevent default right-click menu

        // Retrieve all <li> elements inside the context menu
        const listItems = contextMenu.querySelectorAll('li');

        // Array to store the `data-action` values
        const actions = [];

        // Iterate over each <li> and fetch the data-action attribute
        listItems.forEach((li) => {
            li.setAttribute('data-action', organizationName);
        });
        // Set the position of the menu
        contextMenu.style.left = `${data.event.pageX}px`;
        contextMenu.style.top = `${data.event.pageY - 250}px`;
        setTimeout(() => { contextMenu.style.display = "block"; }, 200);
        // contextMenu.style.display = "block";

    }


    const addClickEventToPlotly = () => {
        setTimeout(() => {
            var plotDivs = document.getElementsByClassName('plotly-graph-div');
            let plotDiv;
            if (plotDivs.length !== 0) {
                plotDiv = plotDivs[0];
                // Add click event listener
                plotDiv.removeEventListener("plotly_click", handlePlotlyClick);
                plotDiv.on('plotly_click', (data) => handlePlotlyClick(data, plotDiv));

            }
        }, 2000);
    };
    useEffect(() => {

        addClickEventToPlotly();

        return () => {
            const plotDivs = document.getElementsByClassName("plotly-graph-div");
            if (plotDivs.length !== 0) {
                let plotDiv = plotDivs[0];

                plotDiv.removeEventListener("plotly_click", handlePlotlyClick);

            }
        };

    }, [htmlString, containerRef.current, plotlyLoaded]);



    useEffect(() => {

        if (plotlyLoaded) {
            const resizeWindowScriptIdentifier = "resize_window_script";
            const script2 = document.createElement('script');
            const topbar = document.getElementById("topbar");
            const topbarHeight = topbar?.getBoundingClientRect()?.height; // Height of the topbar
            const viewportHeight = window?.innerHeight; // Total visible height of the viewport
            let remainingHeight = viewportHeight - topbarHeight - 120; // Remaining visible height


            remainingHeight = remainingHeight < 600 ? 600 : remainingHeight; // Set a minimum height of 600px

            const scriptsToRemove = document.querySelectorAll(
                `script[data-id="${resizeWindowScriptIdentifier}"]`
            );
            if (scriptsToRemove.length != 1) {
                scriptsToRemove.forEach((script) => {
                    document.body.removeChild(script);
                });

                script2.textContent = `window.resizePlotlyCharts = function() {
                        setTimeout(() => {
                            try {
                                let parentDiv = document.getElementById("customReportContainerId");
                                if (parentDiv) {
                                    document.querySelectorAll('.js-plotly-plot').forEach(function (chart) {
                                        Plotly.relayout(chart, { height: `+ remainingHeight + `, width: parentDiv.offsetWidth - 30 }); 
                                    });
                                } else {
                                    console.error("Parent div not found");
                                }
                            } catch (error) {
                                console.error("Error resizing Plotly charts:", error);
                            }
                    }, 1000);
                };

                window.addEventListener('resize', window.resizePlotlyCharts);               
                `;

                script2.setAttribute("data-id", resizeWindowScriptIdentifier);
                document.body.appendChild(script2);

            }






            window.resizePlotlyCharts();

            setTimeout(() => {
                const contextMenu = document.getElementById('context-menu');
                if (contextMenu) {
                    contextMenu.addEventListener('click', (event) => {
                        // Check if the clicked element is an <li>
                        const clickedItem = event.target;
                        if (clickedItem.tagName === 'LI') {

                            const actionType = clickedItem.getAttribute('action-type');
                            const dataAction = clickedItem.getAttribute('data-action');

                            // Call the custom function with the extracted attributes
                            onDrillThrough(actionType, dataAction);
                            contextMenu.style.display = "none";
                        }
                    });

                    document.addEventListener("click", () => {
                        contextMenu.style.display = "none";
                        // addDropdownEventListner();
                    });

                    const containers = document.querySelectorAll('.custom_report_container');

                    containers.forEach((container) => {
                        container.addEventListener('click', () => {
                            contextMenu.style.display = "none";
                        });
                    });


                    const home_btn = document.getElementById('home_btn');
                    if (home_btn) {
                        home_btn.addEventListener('click', function (event) {
                            document.getElementById('ETRBreakdown_btn').click();
                            document.getElementById('home_btn').style.display = "none";

                        });
                    }

                }
                addDropdownEventListner();
            }, 2000);



        }
    }, [plotlyLoaded, containerRef.current, htmlString, htmlStrings]);

    const addDropdownEventListner = () => {

        const dropdowns = document.querySelectorAll(".custom_report_dropdown_class");
        if (dropdowns) {
            dropdowns.forEach((dropdown) => {
                // Check if the event listener flag is not set
                if (!dropdown.hasAttribute('data-event-attached')) {
                    dropdown.addEventListener("change", function () {
                        const selectedValue = JSON.parse(this.value); // Parse the JSON value
                        myCustomFunction(selectedValue.tab_id, selectedValue.val, selectedValue.id);
                    });
                    // Set the event listener flag
                    dropdown.setAttribute('data-event-attached', 'true');
                }
            });
        }
    };
    useEffect(() => {
        setTimeout(() => {
            if (!containerRef.current.innerHTML) {
                containerRef.current.innerHTML = htmlString;
                const scriptTags = containerRef.current.querySelectorAll("script");
                scriptTags.forEach((script) => {
                    const newScript = document.createElement("script");
                    newScript.textContent = script.textContent;
                    newScript.setAttribute("data-id", scriptIdentifier);
                    document.body.appendChild(newScript);
                    document.body.removeChild(newScript);
                });
            }
        }, [300]);

        return () => {
            const scriptsToRemove = document.querySelectorAll(
                `script[data-id="${scriptIdentifier}"]`
            );
            scriptsToRemove.forEach((script) => {
                document.body.removeChild(script);
            });
        };
    }, [containerRef.current]);

    useEffect(() => {
        const topbar = document.getElementById("topbar");
        const footnote = document.getElementById("footnotesAccordionBody"); // Access by id

        if (footnote) {
            const topbarBottom = topbar.getBoundingClientRect().bottom + window.scrollY;
            const footnotePosition = footnote.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
                top: footnotePosition - topbarBottom + 50,
                behavior: "smooth", // Smooth scrolling animation
            });
        } else {
            console.error("Element with id 'footnotesAccordionBody' not found.");
        }
    }, [htmlString]);

    const handleDropdownClose = (event) => {
        const dropdown = document.getElementById("custom-report-org-multiselect-dropdown");
        const dropdownContent = document.querySelector(".custom-report-org-dropdown-content");

        // Check if the click was outside the dropdown
        if (!dropdown.contains(event.target)) {
            dropdownContent.classList.remove("visible");
        }

    };


    useEffect(() => {
        document.addEventListener("click", handleDropdownClose);
        document.getElementById("customReportContainerId").addEventListener("click", handleDropdownClose);
        // document.addEventListener("click", handleDropdownClose);
    }, []);

    let selectedValues = [];

    useEffect(() => {
        const dropdown = document.querySelector(".custom-report-org-multiselect-dropdown");

        if (dropdown && !addedOrgDropdownEvent) {
            const button = dropdown.querySelector(".custom-report-org-dropdown-btn");
            const selectAll = document.getElementById("custom-report-org-select-all");
            const updateBtn = document.getElementById("custom-report-org-click-btn");
            const options = document.querySelectorAll(".custom-report-org-option");
            // Get values from options in array format
            const optionValues = Array.from(options).map(option => option.value);


            // setSelectedOptions(optionValues)



            updateBtn.addEventListener("click", updateSelectedOutput);
            // Handle "Select All" functionality
            selectAll.addEventListener("change", () => {
                const isChecked = selectAll.checked;
                options.forEach((option) => {
                    if (!option.disabled) {
                        option.checked = isChecked;
                    }
                });
                updateSelectedValues();
            });

            // Update selected values when individual options are checked/unchecked
            options.forEach((option) => {
                option.addEventListener("change", () => {
                    if (!option.checked) {
                        selectAll.checked = false;
                    } else if ([...options].every((opt) => opt.checked)) {
                        selectAll.checked = true;
                    }
                    updateSelectedValues();
                });
            });
            // Update selected values array
            function updateSelectedValues() {
                const options = document.querySelectorAll(".custom-report-org-option");
                if (options.length === 0) return;
                selectedValues = [...options]
                    .filter(option => option.checked)
                    .map(option => option.value);

                let selected_value_id = document.getElementById("custom-report-org-dropdown-btn-selected");
                if (options.length === selectedValues.length) {
                    selected_value_id.innerText = "All";
                }
                else if (selectedValues.length === 0) {
                    selected_value_id.innerText = "Select Organization";
                }
                else if (selectedValues.length === 1) {
                    selected_value_id.innerText = selectedValues[0];
                }
                else if (selectedValues.length > 1) {
                    selected_value_id.innerText = "Multiple selections";
                }

                if (selectedValues.length === 0) {
                    updateBtn.disabled = true;
                    updateBtn?.classList.add("disabled-update-btn");

                }
                else {
                    updateBtn.disabled = false;
                    updateBtn?.classList.remove("disabled-update-btn");
                }
                setSelectedOptions(selectedValues);

            }
            setAddedOrgDropdownEvent(true);
        }
    }, [htmlStrings, htmlString, containerRef.current]);


    function updateSelectedOutput() {
        myCustomFunction("ETRBreakDown", selectedValues);
    }
    return (
        <div
            ref={containerRef}
            onClick={e => e.stopPropagation()}
            id="customReportContainerId"
        >
        </div>
    );
}

export default CustomReport;