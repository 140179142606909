import React, { useRef, useState, useEffect } from "react";
import CustomizedToolTip from "../../commonui/tooltip/toolTip";
import { Avatar, Flex } from "@fluentui/react-northstar";
import { ImageUtil } from "../../utility/utility";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import "./avatarUsersList.css";
import MembersDropdown from "../../components/membersdropdown/membersDropdown";

const AvatarUsersList = (props) => {
  const usersList = props?.item;
  const shouldAdjustPosition = props?.shouldAdjustPosition;
  const ishasAccess = props?.ishasAccess;
  const { userInfo } = UseUserInfoContext();
  const isCustomVisualizationHeader = props?.visualizatioManageAccess;
  const totalMembersCount = props?.item?.length;
  const remainingMembersCount = totalMembersCount - 4;
  const remainingMembersData = totalMembersCount > 4 ? totalMembersCount - 4 : "";
  const [isOpen, setIsOpen] = useState(false);
  let extraUsersList = [];
  const memberdropdownRef = useRef(null);
  const triggerRef = useRef(null);

  usersList?.forEach((item) => {
    extraUsersList.push(item);
  });

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        memberdropdownRef.current &&
        !memberdropdownRef.current.contains(e.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document?.addEventListener("click", handleClickOutside);
    return () => document?.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <>
      <div className="membersList">
        {usersList?.map((item, index) => {
          if (index < 4) {
            return (
              <Flex gap="gap.small" key={item.id}>
                <CustomizedToolTip
                  content={item?.name}
                  normalTooltip={true}
                  position="above"
                  align="center"
                >
                  <img
                    className="users-avatar imageoverlapstyle"
                    src={ImageUtil(item?.name)}
                    alt=""
                  />
                </CustomizedToolTip>
              </Flex>
            );
          }

          if (index === 4) {
            return (
              <div ref={triggerRef}>
                <CustomizedToolTip
                  // content={`+${remainingMembersCount}`}
                  content={"View more"}
                  normalTooltip={true}
                  position="above"
                  align="center"
                >
                  <Avatar
                    className="users-avatar-additional add-image-overlap-style"
                    label={`+${remainingMembersCount}`}
                    onClick={() => setIsOpen(!isOpen)}
                  />
                </CustomizedToolTip>
              </div>
            );
          }
        })}
        {isCustomVisualizationHeader && totalMembersCount <= 4 && (
          <div ref={triggerRef} key="trigger-custom-header">
            <CustomizedToolTip
              content={"View more"}
              normalTooltip={true}
              position="above"
              align="center"
            >
              <Avatar
                className="users-avatar-additional add-image-overlap-style"
                label={`+${remainingMembersData}`}
                onClick={() => setIsOpen(!isOpen)}
              />
            </CustomizedToolTip>
          </div>
        )}
      </div>

      {isOpen && (
        <div ref={memberdropdownRef}>
          <MembersDropdown
            key={userInfo?.teams.key ? userInfo?.teams : undefined}
            allOptions={extraUsersList}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            shouldAdjustPosition={shouldAdjustPosition}
            visualizatioManageAccess={isCustomVisualizationHeader}
            ishasAccess={ishasAccess}
          />
        </div>
      )}
    </>
  );
};

export default AvatarUsersList;