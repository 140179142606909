import React, { useState, createContext, useContext } from "react";

// Create the context with default values
export const manageAccessData = createContext({
  accessData: {},
  handleAccessData: (props) => {},
});

// Custom hook to use the context
export function UseManageAccessContext() {
  return useContext(manageAccessData);
}

// Provider component
const ManageAccessContext = ({ children }) => {
  const [accessData, setAccessData] = useState({
    userDetails: [],
    manageAccessType: "",
    dataFileId: 0,
    dataTypeId: 0,
    fileTypeId: 0,
  });

  const handleAccessData = (props) => {
    setAccessData((prevData) => {
      return { ...prevData, ...props };
    });
  };

  return (
    <manageAccessData.Provider value={{ accessData, handleAccessData }}>
      {children}
    </manageAccessData.Provider>
  );
};

export default ManageAccessContext;
