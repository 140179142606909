import React, { useState, createContext, useContext, useEffect } from "react";
import { app } from "@microsoft/teams-js";
import {
  companyFeatureFlags,
  featureFlagObj,
} from "../../data/menu/sideNavMainMenuData";
import APIEndPoints from "../../utility/apiendpoints";
import { getData, postData } from "../../services/customApis";
import { setTelemetryUserContext } from "../../services/appInsights";
import Constant from "../../utility/constants";
import { useMsal } from "@azure/msal-react";
import { isTokenExpired } from "../../utility/utility";

//create contexts
let userObj = {
  displayName: "",
  email: "",
  id: "",
  teams: {
    key: 1,
    text: "",
    schema: "",
    companyId: null,
    companyName: null,
    companyFeatureFlags: companyFeatureFlags,
    featureFlagsObj: featureFlagObj,
    privacyAgreementAccepted: false,
    privacyAgreementDeclined: false,
    isClientTenant: false,
  },
  loading: true,
  allCompanies: [],
  privacyAgreementAccepted: false,
  privacyAgreementDeclined: false,
  isAuthorized: true,
  newUser: false,
  isAccessRequested: false,
  userResponse: "",
  accessMessage: "",
  user: {
    name: "",
    email: "",
    country: "",
    entity: "",
    clientId: 0,
  },
  apiFailed: false,
  apiFailureMessage: "",
  isDemoCompany: false,
  takethetour: false,
  uploadchannellogo: false,
  channelLogo: "",
  noAccessToMenu: false,
  noAccessToMenuName: "",
  noAccessToMenuDone: false,
  isInternal: true,
  showPopup: false,
  isFromDeepLink: false,
  navigateFromPrivacy: false,
  isLoggedInAsGuest: false,
  singlePinCardFetched: false,
  isAdminConsentPresent: false,
  loggedInWeb: null,
};

export const userInfoContext = createContext({
  userInfo: userObj,
  handleUserInfoChange: (props) => {},
});

//create hook contexts
export function UseUserInfoContext() {
  return useContext(userInfoContext);
}

//context provider and states
const UserContext = ({ children }) => {
  // userinfo state
  const [userInfo, setUserInfo] = useState(userObj);
  const { instance: msalInstance, inProgress } = useMsal();
  const [interactionInProgress, setInteractionInProgress] = useState(false);
  var deepLinkCompanyId = 0;

  useEffect(() => {
    let eventString = "";
    app
      .initialize()
      .then(async () => {
        setUserInfo({
          ...userInfo,
          loggedInWeb: false,
        });
        await app.getContext().then((context) => {
          let objectOfUser = {
            email: context.user.userPrincipalName,
            id: context.user.id,
            isTheUserGuest:
              context.user.userPrincipalName.indexOf("#EXT#") > -1
                ? true
                : false,
          };
          let clientId = 0;
          if (context?.page?.subPageId !== "") {
            deepLinkCompanyId = parseInt(
              context?.page?.subPageId?.split(";")?.[1] ?? 0
            );
            setUserInfo({
              ...userInfo,
              isFromDeepLink: true,
              loggedInWeb: false,
            });
            if (
              context?.page?.subPageId?.split(";")?.[0].toLowerCase() ===
              "isinviteduser"
            ) {
              clientId = parseInt(
                context?.page?.subPageId?.split(";")?.[1] ?? 0
              );
            }
          }
          callCommonMethods(objectOfUser, clientId);
        });
      })
      .catch(async (err) => {
        let userObject = {};
        let clientId = 0;
        const url = new URL(window.location.href);
        // Get the pathname and split it into parts
        const parts = url.pathname.split("/");
        // console.log(parts);
        // Remove the first part if it's an empty string (because the pathname starts with a slash)
        if (parts[0] === "") {
          parts?.shift();
        }
        let splitPage = parts[parts.length - 1]?.split(";");
        if (
          splitPage.length > 1 &&
          splitPage[0] !== "" &&
          splitPage[0].toLowerCase() === "isinviteduser"
        ) {
          clientId = parseInt(splitPage[1]);
        }
        if (
          msalInstance.getActiveAccount() &&
          msalInstance.getAllAccounts().length > 0
        ) {
          setUserInfo({
            ...userInfo,
            loggedInWeb: true,
          });
          const account = msalInstance.getActiveAccount();
          if (account !== null) {
            userObject = {
              displayName: account.name,
              email: account.username,
              id: account.username,
            };
          }
          callCommonMethods(userObject, clientId);
        } else {
          const url = new URL(window.location.href);
          if (
            window.location.pathname !== "/register" &&
            window.location.pathname !== "/logout"
          ) {
            const response = await msalInstance.handleRedirectPromise();
            //console.log("response", response);
            if (response) {
              if (response?.account) {
                setUserInfo({
                  ...userInfo,
                  loggedInWeb: true,
                });
                userObject = {
                  displayName: response.account.name,
                  email: response.account.username,
                  id: response.account.username,
                };
                msalInstance.setActiveAccount(response?.account);
                if (response?.state) {
                  const urlObj = new URL(response?.state);
                  const hashParams = new URLSearchParams(
                    urlObj.hash.substring(1)
                  );
                  const stateEncoded = hashParams?.get("state");
                  const urlValue = stateEncoded?.split("|")[1];
                  if (urlValue) {
                    window.location.href = urlValue;
                  } else {
                    callCommonMethods(userObject, clientId);
                  }
                } else {
                  callCommonMethods(userObject, clientId);
                }
              }
            } else {
              if (!interactionInProgress) {
                setInteractionInProgress(true);
                msalInstance
                  .loginRedirect({ state: url?.toString() })
                  .then(() => {})
                  .catch((error) => {
                    if (error.errorCode === "interaction_in_progress") {
                      console.warn("Interaction is already in progress.");
                    } else {
                      console.error("Login failed:", error);
                    }
                  })
                  .finally(() => {
                    setInteractionInProgress(false);
                  });
              }
            }
          } else {
            setUserInfo({
              ...userInfo,
              loggedInWeb: true,
            });
          }
        }
      });
    return () => {
      msalInstance.removeEventCallback(eventString);
    };
  }, []);

  const callCommonMethods = (objectOfUser, clientId) => {
    startUserLoginProcess(objectOfUser, clientId);
    setTelemetryUserContext(objectOfUser.email);
  };

  const getCompanyIdIfPresent = (allCompanies, curUserId) => {
    let val = window.localStorage.getItem("curUserSelectedCompany");
    let companyId = allCompanies?.filter(
      (company) => company.companyId == parseInt(curUserId)
    );
    if (companyId.length > 0) {
      return companyId[0];
    } else {
      if (val == "undefined" || val == null || val == undefined || val == "") {
        return allCompanies[0];
      } else {
        let parsedValue = JSON.parse(val);
        let checkIfExists = allCompanies.filter(
          (item) => item.companyId == parsedValue.companyId
        );
        if (checkIfExists.length > 0) {
          return checkIfExists[0];
        } else {
          return allCompanies[0];
        }
      }
    }
  };

  const getUserDetails = (userSpecificDetails, clientId) => {
    return postData(
      { clientId: clientId },
      APIEndPoints.GET_USERDETAILS,
      false,
      true
    ).catch((err) => {
      userInfoSetupOnError(err, userSpecificDetails);
    });
  };

  const getEyToken = (userSpecificDetails) => {
    // Check if the token exists in local storage
    const token = localStorage.getItem("EyToken");

    if (
      token !== undefined &&
      token !== null &&
      token !== "undefined" &&
      token !== ""
    ) {
      // If the token exists, return it as a resolved promise
      return Promise.resolve(token);
    } else {
      // If the token doesn't exist, make the API call
      return postData({}, APIEndPoints.GET_EY_TOKEN, false, false).catch(
        (err) => {
          userInfoSetupOnError(err, userSpecificDetails);
        }
      );
    }
  };

  const getCompanyDetails = (res, userSpecificDetails) => {
    if (res?.status === 200) {
      if (res.data.isUserExist) {
        getData(APIEndPoints.GET_COMPANY_DETAILS_BY_USER)
          .then((allRetrievedCompanies) => {
            localStorage.setItem("GetCompanyDetailsSuccess", "true");
            // console.log("ALL COMPANIES LOADED", allRetrievedCompanies);
            let curUserSelectedCompany = JSON.parse(
              localStorage.getItem("curUserSelectedCompany")
            );
            // let compExist = false;
            let matchingCompany;
            let matchingCompanyObj;
            if (curUserSelectedCompany || deepLinkCompanyId !== 0) {
              let companyObjIncaseItsPresent = allRetrievedCompanies.data.find(
                (obj) =>
                  obj.companyId ===
                  (deepLinkCompanyId !== 0
                    ? deepLinkCompanyId
                    : curUserSelectedCompany?.companyId)
              );
              if (companyObjIncaseItsPresent) {
                matchingCompany = companyObjIncaseItsPresent;
              } else {
                //check if content is global
                matchingCompany = getCompanyIdIfPresent(
                  allRetrievedCompanies.data,
                  deepLinkCompanyId !== 0 ? deepLinkCompanyId : 0
                );
              }

              if (matchingCompany) {
                matchingCompanyObj = {
                  ...matchingCompany,
                  key: matchingCompany.companyId,
                  text: matchingCompany.companyName,
                  schema: matchingCompany.schema,
                };
                localStorage.setItem(
                  "curUserSelectedCompany",
                  JSON.stringify(matchingCompanyObj)
                );
              }
            }

            let firstDefaultComp = matchingCompany
              ? matchingCompany
              : allRetrievedCompanies.data[0];
            getData(
              APIEndPoints.GET_COMPANY_CONFIG_BY_ID(firstDefaultComp?.companyId)
            )
              .then((companySpecificDetails) => {
                let defaultComp = companySpecificDetails.data.companies[0];
                setUserInfo((prev) => {
                  return {
                    ...prev,
                    ...userSpecificDetails,
                    loading: false,
                    isLoggedInAsGuest: userSpecificDetails.isTheUserGuest,
                    teams: matchingCompany
                      ? {
                          ...matchingCompanyObj,
                          ...defaultComp,
                          companyFeatureFlags: defaultComp.companyFeatureFlags,
                          isSelected: defaultComp.isSelected,
                          isClientTenant: defaultComp.isClientTenant,
                          privacyAgreementDeclined:
                            !defaultComp?.privacyAgreementAccepted,
                          privacyAgreementAccepted:
                            defaultComp?.privacyAgreementAccepted,
                        }
                      : deepLinkCompanyId !== 0
                      ? userInfo.teams
                      : {
                          ...defaultComp,
                          key: defaultComp.companyId,
                          text: defaultComp.companyName,
                          schema: defaultComp.schema,
                          companyFeatureFlags: defaultComp.companyFeatureFlags,
                          isSelected: defaultComp.isSelected,
                          privacyAgreementDeclined:
                            !defaultComp?.privacyAgreementAccepted,
                          isClientTenant: defaultComp.isClientTenant,
                        },
                    allCompanies: allRetrievedCompanies.data.map((company) => {
                      let comp = {
                        ...company,
                        key: company.companyId,
                        text: company.companyName,
                        schema: company.schema,
                      };
                      return comp;
                    }),
                    isAuthorized:
                      deepLinkCompanyId !== 0
                        ? matchingCompany !== undefined
                        : true,
                    newUser: false,
                    apiFailed: false,
                    isAdminConsentPresent: res?.data?.isAdminConsentPresent,
                  };
                });
              })
              .catch((err) => {
                setUserInfo((prev) => {
                  return {
                    ...prev,
                    ...userSpecificDetails,
                    loading: false,
                    isLoggedInAsGuest: userSpecificDetails.isTheUserGuest,
                    teams: matchingCompany
                      ? {
                          ...prev.teams,
                          ...matchingCompanyObj,
                        }
                      : userInfo.teams,
                    apiFailed: true,
                    isAuthorized:
                      deepLinkCompanyId !== 0
                        ? matchingCompany !== undefined
                        : true,
                    newUser: false,
                    apiFailureMessage: err?.response?.data?.Errors,
                    allCompanies: allRetrievedCompanies.data.map((company) => {
                      let comp = {
                        ...company,
                        key: company.companyId,
                        text: company.companyName,
                        schema: company.schema,
                      };
                      return comp;
                    }),
                  };
                });
              });
          })
          .then(() => {
            getData(
              APIEndPoints.AD_USER_SEARCH(
                userSpecificDetails.email.toLowerCase().trim(),
                null,
                null,
                null,
                null
              )
            ).then((res) => {
              res?.data?.length > 0 &&
                setUserInfo((prev) => {
                  return {
                    ...prev,
                    displayName: res?.data?.[0].displayName,
                    isInternal: res?.data?.[0].isInternal,
                    isLoggedInAsGuest: userSpecificDetails.isTheUserGuest,
                  };
                });
            });
          })
          .catch((err) => {
            console.log(userInfo);
            if (err.response.status === 419) {
              window.location.href = Constant.REDIRECTION_PATH;
            } else {
              setUserInfo((prev) => {
                return {
                  ...prev,
                  loading: false,
                  apiFailed: true,
                  apiFailureMessage: err?.response?.data?.Errors,
                  isLoggedInAsGuest: userSpecificDetails.isTheUserGuest,
                };
              });
            }
          });
      } else {
        setUserInfo((prev) => {
          return {
            ...prev,
            newUser: true,
            isAdminConsentPresent: res?.data?.isAdminConsentPresent,
            isAccessRequested: res.data?.isAccessRequested,
            accessMessage: res.data?.message,
            userResponse: res.data?.userResponse,
            user: {
              name: res.data.name,
              email: res.data.userEmail,
              country: res.data.country,
              //entity: res.data.upeOrganization,
              entity: res.data.companyName,
              clientId: res.data.clientId,
            },
            loading: false,
            isLoggedInAsGuest: userSpecificDetails.isTheUserGuest,
          };
        });
      }
    } else {
      // console.log("res value", res);
      if (res === undefined || res == null) {
        if (typeof localStorage !== "undefined") {
          localStorage.removeItem("EyToken");
        }
        window.location.href = "/";
      } else {
        window.location.href = "/unauthorized";
      }
    }
  };

  const startUserLoginProcess = (objectOfUser, clientId) => {
    // Promise.all([
    //   getUserDetails(objectOfUser, clientId),
    //   getEyToken(objectOfUser),
    // ]).then((res) => {
    //   //first set the token in local storage
    //   if (res[1]?.data?.token) {
    //     localStorage.setItem("EyToken", res[1]?.data?.token);
    //   } else {
    //     localStorage.setItem("EyToken", res[1]);
    //   }
    //   //now call the company details
    //   getCompanyDetails(res[0], objectOfUser);
    // });
    getEyToken(objectOfUser).then((res) => {
      if (res?.data?.token) {
        localStorage.setItem("EyToken", res?.data?.token);
      } else {
        localStorage.setItem("EyToken", res);
      }
      getUserDetails(objectOfUser).then((res2) => {
        getCompanyDetails(res2, objectOfUser);
      });
    });
  };

  const userInfoSetupOnError = (err, userSpecificDetails) => {
    if (err?.response.status === 401) {
      setUserInfo({
        ...userInfo,
        loading: false,
        isAuthorized: false,
        apiFailed: true,
        isLoggedInAsGuest: userSpecificDetails?.isTheUserGuest,
      });
    } else {
      setUserInfo({
        ...userInfo,
        loading: false,
        apiFailed: true,
        apiFailureMessage: err?.response?.data?.Errors,
      });
    }
  };

  useEffect(() => {
    const featureFlagsObject = userInfo.teams.companyFeatureFlags.reduce(
      (acc, obj) => {
        acc[obj.featureFlagName] = {
          subMenuItemId: obj.subMenuItemId,
          featureFlagName: obj.featureFlagName,
          isEnabled: obj.isEnabled,
        };
        return acc;
      },
      {}
    );

    // Append "Request Channel" "Channel Settings" manually
    featureFlagsObject["Request Channel"] = {
      subMenuItemId: 200,
      featureFlagName: "Request Channel",
      isEnabled: true,
    };
    featureFlagsObject["Channel Settings"] = {
      subMenuItemId: 201,
      featureFlagName: "Channel Settings",
      isEnabled: true,
    };

    setUserInfo((prevData) => {
      return {
        ...prevData,
        teams: {
          ...prevData.teams,
          featureFlagsObj: featureFlagsObject,
        },
      };
    });

    setTimeout(() => {
      document.getElementById("uniqueSideBarId")?.scrollTo(0, 0);
    }, 1000);

    handleUserInfoChange({
      isDemoCompany:
        userInfo?.teams?.text?.toLowerCase() ===
        Constant.DEMO_COMPANY_NAME?.toLowerCase(),
    });
  }, [userInfo.teams.companyId]);

  useEffect(() => {
    setInterval(() => {
      tokenCall();
    }, 25 * 60 * 1000);
  }, []);

  useEffect(() => {
    if (userInfo?.allCompanies.length > 0) {
      setInterval(async () => {
        let savedToken = localStorage.getItem("msal.teamsIdToken");
        if (
          savedToken === null ||
          savedToken === undefined ||
          savedToken === "" ||
          savedToken === "undefined"
        ) {
          tokenCall();
        } else {
          let hasTokenExpired = isTokenExpired(savedToken);
          //console.log("Token Expired", hasTokenExpired);
          if (hasTokenExpired) {
            tokenCall();
          }
        }
      }, 1000);
    }
  }, [userInfo?.allCompanies?.length]);
  //change event handler function

  const tokenCall = () => {
    localStorage.removeItem("EyToken");
    getEyToken({}).then((res) => {
      if (res?.data?.token) {
        localStorage.setItem("EyToken", res?.data?.token);
      } else {
        localStorage.setItem("EyToken", res);
      }
    });
  };

  const handleUserInfoChange = (props) => {
    // alert(props)
    props.hasOwnProperty("teams")
      ? updateCompanyFlags(props.teams)
      : setUserInfo((prevData) => {
          return { ...prevData, ...props };
        });
  };

  const updateCompanyFlags = (companyProps) => {
    if (companyProps?.companyId) {
      setUserInfo((prevData) => {
        return {
          ...prevData,
          teams: {
            ...prevData.teams,
            ...companyProps,
            privacyAgreementAccepted:
              userInfo?.allCompanies?.filter(
                (item) => item.companyId === companyProps?.companyId
              )[0]?.privacyAgreementAccepted ?? false,
          },
        };
      });
      getData(
        APIEndPoints.GET_COMPANY_CONFIG_BY_ID(companyProps?.companyId)
      ).then((companySpecificDetails) => {
        let defaultComp = companySpecificDetails.data.companies[0];
        setUserInfo((prevData) => {
          return {
            ...prevData,
            loading: false,
            apiFailed: false,
            apiFailureMessage: "",
            teams: {
              ...defaultComp,
              key: defaultComp.companyId,
              text: defaultComp.companyName,
              schema: defaultComp.schema,
              companyFeatureFlags: defaultComp.companyFeatureFlags,
              featureFlagsObj: defaultComp.companyFeatureFlags.reduce(
                (acc, obj) => {
                  acc[obj.featureFlagName] = {
                    subMenuItemId: obj.subMenuItemId,
                    featureFlagName: obj.featureFlagName,
                    isEnabled: obj.isEnabled,
                  };
                  return acc;
                },
                {}
              ),
              isSelected: defaultComp.isSelected,
              isClientTenant: defaultComp.isClientTenant,
              privacyAgreementDeclined: !defaultComp?.privacyAgreementAccepted,
              privacyAgreementAccepted: defaultComp?.privacyAgreementAccepted,
            },
          };
        });

        // Append "Request Channel" "Channel Settings" manually
        setUserInfo((prevData) => {
          return {
            ...prevData,
            teams: {
              ...prevData.teams,
              featureFlagsObj: {
                ...prevData.teams.featureFlagsObj,
                "Request Channel": {
                  subMenuItemId: 200,
                  featureFlagName: "Request Channel",
                  isEnabled: true,
                },
                "Channel Settings": {
                  subMenuItemId: 201,
                  featureFlagName: "Channel Settings",
                  isEnabled: true,
                },
              },
            },
          };
        });
      });
    } else {
      setUserInfo((prevData) => {
        return {
          ...prevData,
          teams: {
            ...prevData.teams,
            ...companyProps,
          },
        };
      });
    }
  };

  return (
    <userInfoContext.Provider value={{ userInfo, handleUserInfoChange }}>
      {children}
    </userInfoContext.Provider>
  );
};

export default UserContext;
