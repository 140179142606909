export const checkIfInputIsValid = (validationarr: [], currentValue) => {
  let hasError = false,
    errMessage = "";

  validationarr.every((element: any) => {
    switch (element.name) {
      case "MaxLength":
        if (currentValue.length > element.maxChar) {
          hasError = true;
          errMessage = element.errorMessage;
        }
        break;
      case "MaxCount":
        if (currentValue.length > element.maxCount) {
          hasError = true;
          errMessage = element.errorMessage;
        }
        break;
      case "FileType":
        let checkIfFileTypeExists = element.allowedTypes.filter((item) => {
          let type = currentValue.type;
          if (type !== "" && type !== null && type !== undefined) {
            return currentValue.type.indexOf(item) > -1;
          } else {
            //check for file extension
            const fileName = currentValue.name;
            const fileExtension = fileName.split(".").pop().toLowerCase();
            return item === fileExtension;
          }
        });
        if (checkIfFileTypeExists.length == 0) {
          hasError = true;
          errMessage = element.errorMessage;
        }
        break;
      case "FileLength":
        if (currentValue.size / 1000 > element.maxLength) {
          hasError = true;
          errMessage = element.errorMessage;
        }
        break;
      case "ValidURL":
        if (currentValue != "") {
          if (currentValue.indexOf("https://") != 0) {
            hasError = true;
            errMessage = element.errorMessage;
          }
        }
        break;
      case "ValidLinkedInURL":
        if (currentValue != "") {
          if (currentValue.indexOf("https://www.linkedin.com") != 0) {
            hasError = true;
            errMessage = element.errorMessage;
          }
        }
        break;
      case "SpecialChar":
        let regex = new RegExp(element.allowedSpecialChars.replace("/", "//"));
        let indexOfLastDot = currentValue.name.lastIndexOf(".");
        let onlyFileName = currentValue.name.substring(0, indexOfLastDot);
        let substr = "";
        if (!regex.test(onlyFileName.toLowerCase())) {
          hasError = true;
          for (var i = 0; i < onlyFileName.length; i++) {
            if (!regex.test(onlyFileName[i])) {
              substr = onlyFileName[i];
              break;
            }
          }
          errMessage =
            substr === " "
              ? "Consecutive spaces are not allowed in file name"
              : element.errorMessage.replace("{CHAR}", substr);
        }
        break;
      case "IsValidValue":
        if (
          currentValue == "" ||
          currentValue == null ||
          currentValue == undefined
        ) {
          hasError = true;
          errMessage = element.errorMessage;
        }
        break;
      case "MinCount":
        if (currentValue.length < element.MinCount) {
          hasError = true;
          errMessage = element.errorMessage;
        }
        break;
      case "AllowedSpecialCharsForInternalApp":
        const valueToCheck = typeof currentValue === 'object' 
        ? (currentValue?.name.includes('.') ? currentValue?.name.split('.').slice(0, -1).join('.') : currentValue?.name)
        : currentValue;
        if (
          !new RegExp(element.allowedSpecialChars).test(
            valueToCheck.toLowerCase()
          )
        ) {
          hasError = true;
          errMessage = element.errorMessage;
        }
        break;
        case "NotAllowedSpecialCharsForInternalApp":
          if (new RegExp(element.notAllowedSpecialChars).test(currentValue.toLowerCase())) {
            hasError = true;
            errMessage = element.errorMessage;
          }
          break;
    }
    if (hasError) {
      return false;
    }
    return true;
  });
  return { hasError, errMessage };
};
